// eslint-disable-next-line no-use-before-define
import React from 'react'
import css from '../styles/components/TheFooter.m.module.css'
/*  */
import {
  RightOutlined
} from '@ant-design/icons'
/* page */
interface IPageProps {
}
interface IPageState {
  downVisible: boolean,
  aboutVisible: boolean,
  connectVisible: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      downVisible: false,
      aboutVisible: false,
      connectVisible: false
    }
  }

  private onDownloadClick = () => {
    this.setState({ downVisible: !this.state.downVisible })
  }

  private onAboutClick = () => {
    this.setState({ aboutVisible: !this.state.aboutVisible })
  }

  private onConnectClick = () => {
    this.setState({ connectVisible: !this.state.connectVisible })
  }

  render () {
    return <div className={ css.footer }>
      <div className={css.title} onClick={this.onDownloadClick}>
        <span>下载</span>
        <RightOutlined className={ css.title_icon } style={{ fontSize: 20, transform: `rotate(${this.state.downVisible ? '90' : '0'}deg)` }}/>
      </div>
      <div style={{ height: this.state.downVisible ? 96 : 0 }} className={css.content}>
        <div><a href="./download.html" style={{ color: 'inherit' }}>APP</a></div>
        <div><a href="./download.html" style={{ color: 'inherit' }}>小程序</a></div>
      </div>
      {/*  */}
      <div className={css.title} onClick={this.onAboutClick}>
        <span>关于</span>
        <RightOutlined className={ css.title_icon } style={{ fontSize: 20, transform: `rotate(${this.state.aboutVisible ? '90' : '0'}deg)` }}/>
      </div>
      <div style={{ height: this.state.aboutVisible ? 48 : 0 }} className={css.content}>
        <div><a href="./about.html" style={{ color: 'inherit' }}>关于我们</a></div>
      </div>
      {/*  */}
      <div className={css.title} onClick={this.onConnectClick}>
        <span>联系方式</span>
        <RightOutlined className={ css.title_icon } style={{ fontSize: 20, transform: `rotate(${this.state.connectVisible ? '90' : '0'}deg)` }}/>
      </div>
      <div style={{ height: this.state.connectVisible ? 230 : 0 }} className={css.content}>
        <div>电话：<a href="tel:025-85991069" style={{ color: 'inherit', display: 'inline' }} target="_blank" rel="noreferrer">025-85991069</a></div>
        <div>Q&nbsp;Q： 32907255744</div>
        <div>微信：haoqiankefu</div>
        <div>地址：江苏省南京市雨花台区软件谷科创城</div>
      </div>
      {/* 分割线 */}
      <div className={css.division}></div>
      <div className={css.copy}>
        © 2015-2022 南京好签软件技术有限公司
        <br/>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" style={{ textDecoration: 'underline', color: 'inherit' }} target="_blank" rel="noreferrer">苏ICP备15058199号-1</a>
      </div>
    </div>
  }
}
