// eslint-disable-next-line no-use-before-define
import React from 'react'
/* UI */
import css from '../styles/components/TheFooter.module.css'
import ImgLogo from '../assets/header.logo.png'
import ImgConnect1 from '../assets/theFooter/connect1.png'
import ImgConnect2 from '../assets/theFooter/connect2.png'
import ImgConnect3 from '../assets/theFooter/connect3.png'
// import ImgConnect4 from '../assets/theFooter/connect4.png'

import {
  Divider,
  Popover
} from 'antd'
import ImgKeFu15 from '../assets/theFooter/kefu15.png'
import ImgKeFu67 from '../assets/theFooter/kefu67.png'
import dayjs from 'dayjs'
// const ImgQRCode = 'https://pub.wellsign.cn/website/img/theFooter/qrcode.png'
const ImgQRCode2 = 'https://pub.wellsign.cn/website/img/theFooter/qrcode2.jpg'
const ImgQRCode3 = 'https://pub.wellsign.cn/website/img/theFooter/qrcode3.jpg'
/* DI */

interface IPageProps {
  backgroundColor?: string
}
interface IPageState {}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {}
  }

  render () {
    const isWeekend = [0, 6].includes(dayjs().day())
    return <div className={ css.footer } style={ this.props.backgroundColor ? { backgroundColor: this.props.backgroundColor } : {} }>
      <div>
        <div className={ css.footer_columns }>
          <div style={{ width: 170 }}>
            <img className={ css.footer_logo } src={ ImgLogo }></img>
            <div className={ css.footer_connect }>
              <Popover
                placement="top"
                content={<img src={ isWeekend ? ImgKeFu67 : ImgKeFu15 } style={{ width: 160, height: 160 }}/>}>
                <img src={ ImgConnect1 }/>
              </Popover>
              <Popover
                placement="top"
                content={<img src={ImgQRCode2} style={{ width: 300 }}/>}>
                <img src={ ImgConnect2 }/>
              </Popover>
              <Popover
                placement="top"
                content={<img src={ImgQRCode3} style={{ width: 300 }}/>}>
                <img src={ ImgConnect3 }/>
              </Popover>
              {/* <a href="https://weibo.com/5787132041/about" target="_blank" rel="noreferrer">
                <img style={{ width: 21, height: 21 }} src={ ImgConnect4 }/>
              </a> */}
            </div>
          </div>
          <div style={{ width: 120 }}>
            <div>产品</div>
            <div><a href="./product.html">产品功能</a></div>
            <div><a href="./effect.html">安全和法律保障</a></div>
            <div><a href="./open.html">开放平台</a></div>
            <div><a href="./price.html">价格</a></div>
            <div><a href="./video.html">使用教程</a></div>
          </div>
          <div style={{ width: 120 }}>
            <div>下载</div>
            <div><a href="./download.html">APP</a></div>
            <div><a href="./download.html">小程序</a></div>
          </div>
          <div style={{ width: 120 }}>
            <div>关于</div>
            <div><a href="./about.html">关于我们</a></div>
            <div><a href="https://my.wellsign.cn/pdffile/fuwu.pdf" target="_blank" rel="noreferrer">服务协议</a></div>
            <div><a href="https://my.wellsign.cn/pdffile/yinsi.pdf" target="_blank" rel="noreferrer">隐私政策</a></div>
            <div><a href="https://my.wellsign.cn/pdffile/shiming.pdf" target="_blank" rel="noreferrer">实名认证服务协议</a></div>
          </div>
          <div style={{ width: 120, whiteSpace: 'nowrap' }}>
            <div>联系方式</div>
            <div>电话：025-85991069</div>
            {/* <div>Q&nbsp;&nbsp;Q：<a href="https://wpa.qq.com/msgrd?v=3&uin=3290725774&site=qq&menu=yes" target="_blank" style={{ color: 'inherit' }} rel="noreferrer">32907255744</a></div> */}
            {/* <div>地址：南京市雨花台区铁心桥街道大周路32号<br/><span style={{ visibility: 'hidden' }}>地址：</span>南京软件谷科创城D1栋706室</div> */}
            <div style={{ lineHeight: '12px', display: 'flex' }}>
              <span>微信：</span>
              <img src={ isWeekend ? ImgKeFu67 : ImgKeFu15 } style={{ width: 70, height: 70 }}/>
            </div>
          </div>
        </div>
        <Divider style={{ borderTopWidth: 2 }}/>
        <div className={ css.footer_record }>
          © 2015-2022 南京好签软件技术有限公司&nbsp;&nbsp;
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style={{ textDecoration: 'underline', color: 'inherit' }} target="_blank" rel="noreferrer">苏ICP备15058199号-1</a>
        </div>
      </div>
    </div>
  }
}
