// eslint-disable-next-line no-use-before-define
import React from 'react'
import axios from 'axios'
/* UI */
import {
  Modal,
  // Form,
  // Input,
  // Select,
  // Button,
  message
} from 'antd'
import css from '../styles/components/TheSDKTrialModal.module.css'
import ImgPhone from '../assets/trialModal/phone.png'
// import ImgQQ from '../assets/trialModal/qq.png'
import ImgWechat from '../assets/trialModal/wechat.png'
// import ImgWechatQRCode from '../assets/footer.qrcode.2.png'
import ImgKeFu15 from '../assets/theFooter/kefu15.png'
import ImgKeFu67 from '../assets/theFooter/kefu67.png'
import dayjs from 'dayjs'
/* DI */

interface IPageProps {}
interface TrialForm {
  name: string,
  phone: string,
  companyName: string,
  type: string | undefined
}
interface IPageState {
  modalVisible: boolean,
  trialForm: TrialForm,
  submitLoading: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      modalVisible: false,
      trialForm: {
        name: '',
        phone: '',
        companyName: '',
        type: undefined
      },
      submitLoading: false
    }
  }

  public show = () => {
    this.setState({
      modalVisible: true,
      trialForm: {
        name: '',
        phone: '',
        companyName: '',
        type: undefined
      }
    })
  }

  private onNameChange = (e: any) => {
    this.setState(s => {
      s.trialForm.name = e.target.value
      return s
    })
  }

  private onPhoneChange = (e: any) => {
    this.setState(s => {
      s.trialForm.phone = e.target.value.replace(/[^0-9]/g, '')
      return s
    })
  }

  private onCompanyNameChange = (e: any) => {
    this.setState(s => {
      s.trialForm.companyName = e.target.value
      return s
    })
  }

  private onTypeChange = (value: any) => {
    this.setState(s => {
      s.trialForm.type = value
      return s
    })
  }

  private onSubmit = async () => {
    const {
      name,
      phone,
      companyName,
      type
    } = this.state.trialForm
    if (name.trim() === '') {
      message.error('请填写姓名', 2)
    } else if (phone.trim() === '') {
      message.error('请填写手机号', 2)
    } else if (phone.length !== 11) {
      message.error('请填写正确手机号码', 2)
    } else if (companyName.trim() === '') {
      message.error('请填写企业名称', 2)
    } else if (type === '') {
      message.error('请选择咨询服务', 2)
    } else {
      this.setState({ submitLoading: true })
      try {
        // https://app.wellsign.cn/BackD00rKanli/3.0
        // https://dev.wellsign.cn/java/superconsole/1.0/contacts/notify
        const response = await axios.post('https://app.wellsign.cn/BackD00rKanli/3.0/contacts/notify', {
          phone: this.state.trialForm.phone,
          userName: this.state.trialForm.name,
          companyName: this.state.trialForm.companyName,
          serviceName: this.state.trialForm.type
        }, {
          transformRequest: [data => {
            const formData = new FormData()
            for (const key in data) { formData.append(key, data[key]) }
            return formData
          }]
        })
        this.setState({ submitLoading: false })
        if (response.data && response.data.succeed === 1) {
          message.success('申请提交成功，好签会尽快与您联系。')
          this.setState({ modalVisible: false })
        } else if (response.data && response.data.msg) {
          message.success(response.data.msg)
        } else {
          message.warning('申请提交失败')
        }
      } catch (e) {
        this.setState({ submitLoading: false })
        Modal.error({ title: '服务器内部错误', content: '您可以致电、QQ或微信联系好签。' })
      }
    }
  }

  render () {
    const isWeekend = [0, 6].includes(dayjs().day())
    return <Modal
      visible={ this.state.modalVisible }
      title={ null }
      footer={ null }
      width={ 502 }
      onCancel={() => {
        this.setState({ modalVisible: false })
      }}
      bodyStyle={{ padding: '50px' }}>
      <div className={ css.modal_title }>免费试用&咨询</div>
      {/* <div className={ css.modal_title2 }>留下您的联系方式，会有专门的工作人员联系您</div> */}
      <div className={ css.modal_column }>
        {/* left */}
        {/* <div>
          <Form layout="vertical">
            <Form.Item label="姓名" required>
              <Input
                value={ this.state.trialForm.name }
                maxLength={ 24 }
                placeholder="请输入您的姓名"
                onChange={ this.onNameChange }/>
            </Form.Item>
            <Form.Item label="手机号" required>
              <Input
                value={ this.state.trialForm.phone }
                maxLength={ 11 }
                placeholder="请输入手机号码"
                onChange={ this.onPhoneChange }/>
            </Form.Item>
            <Form.Item label="企业名称" required>
              <Input
                value={ this.state.trialForm.companyName }
                maxLength={ 47 }
                placeholder="请输入您的企业名称"
                onChange={ this.onCompanyNameChange }/>
            </Form.Item>
            <Form.Item label="你想咨询&试用什么服务" required>
              <Select
                value={ this.state.trialForm.type }
                placeholder="请选择咨询&试用的服务"
                onChange={ this.onTypeChange }>
                <Select.Option value="本地部署">本地部署</Select.Option>
                <Select.Option value="SDK">SDK</Select.Option>
                <Select.Option value="SasS产品">SasS产品</Select.Option>
                <Select.Option value="我想了解哪种计划适合我">我想了解哪种计划适合我</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button style={{ width: 120, borderRadius: 4 }} loading={ this.state.submitLoading } size="large" type="primary" onClick={ this.onSubmit }>提交申请</Button>
            </Form.Item>
          </Form>
        </div> */}
        {/* right */}
        <div>
          <div className={ css.modal_connect }>
            <img style={{ height: 36 }} src={ImgPhone}/>
            <span>025-85991069</span>
          </div>
          {/* <div className={ css.modal_connect }>
            <img style={{ height: 36 }} src={ImgQQ}/>
            <span style={{ textDecoration: 'underline' }}>
              <a href="http://wpa.qq.com/msgrd?v=3&uin=3290725774&site=qq&menu=yes" style={{ color: 'inherit' }} target="_blank" rel="noreferrer">3290725774</a>
            </span>
          </div> */}
          <div className={ css.modal_connect }>
            <img style={{ height: 36 }} src={ImgWechat}/>
            <span>好签微信客服</span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <img src={ isWeekend ? ImgKeFu67 : ImgKeFu15 } style={{ width: 115, height: 115 }}/>
          </div>
        </div>
        {/* end */}
      </div>
      {/* end */}
    </Modal>
  }
}
