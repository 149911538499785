// eslint-disable-next-line no-use-before-define
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import css from '../styles/components/TheHeader.m.module.css'
/*  */
import {
  Modal
} from 'antd'
import {
  CloseOutlined,
  MenuOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons'
/* 图片 */
import ImgLogo from '../assets/header.m.logo.png'
// import ImgDingDing from '../assets/temp/dingding.png'
/* 怕个 */
interface IPageProps {
}
interface IPageState {
  position: boolean,
  menuCollapsed: boolean,
  productCollapsed: boolean,
  loginCollapsed: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      position: false,
      menuCollapsed: false,
      productCollapsed: false,
      loginCollapsed: false
    }
  }

  componentDidMount () {
    const rootEl = window.document.getElementById('IndexM')
    if (rootEl) { rootEl.addEventListener('scroll', this.onPageScroll.bind(this, rootEl)) }
  }

  componentWillUnmount () {
    const rootEl = window.document.getElementById('IndexM')
    if (rootEl) { rootEl.removeEventListener('scroll', this.onPageScroll.bind(this, rootEl)) }
  }

  private onPageScroll = (pageEl: Element) => {
    this.setState({ position: pageEl.scrollTop > 72 })
  }

  render () {
    return <div className={ css.header }>
      <CSSTransition
        in={ this.state.position }
        timeout={ 0 }
        classNames="header-m-fade">
        <div className={ (this.state.position) ? css.headerNavPosi : css.headerNav }>
          <div>
            <a href="./index.html">
              <img src={ ImgLogo } alt="logo"></img>
            </a>
            {/* <a href="https://mp.weixin.qq.com/s/vOdFkE5B6dupEVaKFICvlA" style={{ marginLeft: 9 }}>
              <img style={{ width: 77, height: 24 }} src={ImgDingDing}/>
            </a> */}
          </div>
          <span style={{ padding: '10px 0' }} onClick={() => {
            let position = true
            const rootEl = window.document.getElementById('IndexM')
            if (this.state.menuCollapsed && rootEl && rootEl.scrollTop <= 72) {
              position = false
            }
            this.setState({
              menuCollapsed: !this.state.menuCollapsed,
              position
            })
          }}>
            { this.state.menuCollapsed ? <CloseOutlined/> : <MenuOutlined/> }
          </span>
        </div>
      </CSSTransition>
      {/* 收缩 */}
      <div className={ css.headerBody } style={{ height: this.state.menuCollapsed ? 'calc(100% - 72px)' : 0 }}>
        <div style={{ height: 30 }}></div>
        <div>
          <div onClick={() => { this.setState({ productCollapsed: !this.state.productCollapsed }) }} className={ css.item_title }>
            <a>产品</a>
            { this.state.productCollapsed ? <UpOutlined/> : <DownOutlined/> }
          </div>
          <div className={ css.item_subs } style={{ height: this.state.productCollapsed ? 0 : '184px' }}>
            <a href="./product.html">产品功能</a>
            <a href="./effect.html">安全和法律保障</a>
            <a href="./download.html">下载好签</a>
            <a href="./video.html">使用教程</a>
          </div>
        </div>
        <div>
          <div className={ css.item_title }>
            <a href="./open.html">开放平台</a>
          </div>
        </div>
        <div>
          <div className={ css.item_title }>
            <a href="./price.html">价格</a>
          </div>
        </div>
        <div>
          <div className={ css.item_title }>
            <a href="https://app.wellsign.cn/" target="_blank" rel="noreferrer">登录好签APP</a>
          </div>
        </div>
        <div>
          <div className={ css.item_title }>
            <a href="https://my.wellsign.cn/" target="_blank" rel="noreferrer">登录好签小程序</a>
          </div>
        </div>
        {/* <div>
          <div className={ css.item_title }>
            <a href="https://app.wellsign.cn" target="_blank" rel="noreferrer">登录电脑端</a>
          </div>
        </div> */}
      </div>
    </div>
  }

  private onApp = () => {
    Modal.warning({
      title: '手机官网暂不支持登录APP电脑版',
      content: '请使用电脑登录',
      okText: '确定'
    })
  }

  private onWechat = () => {
    Modal.warning({
      title: '手机官网暂不支持登录小程序电脑版',
      content: '请使用电脑登录',
      okText: '确定'
    })
  }
}
