// eslint-disable-next-line no-use-before-define
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import {
  Button,
  Dropdown,
  Menu
} from 'antd'
import {
  DownOutlined
} from '@ant-design/icons'
import css from '../styles/components/TheHeader.module.css'
import ImgHeaderLogo from '../assets/header.logo.png'

interface IPageProps {
}
interface IPageState {
  optionVisible: boolean,
  loginVisible: boolean,
  position: boolean,
  isDisabledBtn: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      optionVisible: false,
      loginVisible: false,
      position: false,
      isDisabledBtn: false
    }
  }

  private onFreeTrial = () => {
    window.location.href = './download.html'
  }

  componentDidMount () {
    const rootEl = window.document.getElementById('index')
    if (rootEl) {
      rootEl.addEventListener('scroll', this.onPageScroll.bind(this, rootEl))
    }
    if (location.href.includes('download.html')) {
      this.setState({ isDisabledBtn: true })
    }
  }

  componentWillUnmount () {
    const rootEl = window.document.getElementById('index')
    if (rootEl) {
      rootEl.removeEventListener('scroll', this.onPageScroll.bind(this, rootEl))
    }
  }

  private onPageScroll = (pageEl: Element) => {
    if (pageEl.scrollTop > 120) {
      this.setState({ position: true })
    } else {
      this.setState({ position: false })
    }
  }

  render () {
    return <div style={{ height: 120 }}>
      <CSSTransition
        in={ this.state.position }
        timeout={ 0 }
        classNames="header-fade">
        <div className={ this.state.position ? css.header_posi : css.header }>
          <div>
            <a href="./index.html">
              <img className={ css.header_logo } src={ ImgHeaderLogo }/>
            </a>
            <div className={ css.header_option }>
              <Dropdown
                onVisibleChange={(visible: boolean) => { this.setState({ optionVisible: visible }) }}
                overlay={<Menu
                  style={{ width: 240 }}>
                  <Menu.Item key="1">
                    <a href="./product.html">产品功能</a>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <a href="./effect.html">安全与法律保障</a>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <a href="./download.html">下载好签</a>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <a href="./video.html">使用教程</a>
                  </Menu.Item>
                </Menu>}>
                <span style={ this.state.optionVisible ? { color: '#DD444C', lineHeight: '40px' } : { lineHeight: '40px' } }>
                  &nbsp;产品功能&nbsp;<DownOutlined style={{ fontSize: 13, transform: this.state.optionVisible ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 0.5s' }}/>
                </span>
              </Dropdown>
              <span><a href="./open.html">开放平台</a></span>
              <span><a href="./price.html">价格</a></span>
            </div>
            <div className={ css.header_login }>
              <Dropdown
                onVisibleChange={(visible: boolean) => { this.setState({ loginVisible: visible }) }}
                overlay={<Menu
                  style={{ width: 240 }}>
                  <Menu.Item key="1">
                    <a href="https://app.wellsign.cn/" target="_blank" rel="noreferrer">&nbsp;&nbsp;登录&nbsp;&nbsp;好签APP</a>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <a href="https://my.wellsign.cn/" target="_blank" rel="noreferrer">&nbsp;&nbsp;登录&nbsp;&nbsp;好签小程序</a>
                  </Menu.Item>
                </Menu>}>
                <span style={ this.state.loginVisible ? { color: '#DD444C' } : {} } className={ css.header_login_dropdown }>
                  &nbsp;登录&nbsp;<DownOutlined style={{ fontSize: 13, transform: this.state.loginVisible ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 300ms' }}/>
                </span>
              </Dropdown>
              {/* <Button className={ css.header_login_try } size="large" ghost type="primary" danger style={{ marginRight: 20 }}>
                <a href="https://my.wellsign.cn" target="_blank" rel="noreferrer">登录</a>
              </Button> */}
              <Button onClick={ this.onFreeTrial } disabled={ this.state.isDisabledBtn } style={ this.state.isDisabledBtn ? { backgroundColor: 'rgba(221,68,76,.5)', color: 'white' } : {} } type="primary" size="large" className={ css.header_login_try }>免费试用</Button>
            </div>
            {/*  */}
          </div>
        </div>
      </CSSTransition>
    </div>
  }
}
