// eslint-disable-next-line no-use-before-define
import React from 'react'
import ImgMsg from '../assets/trialModal/trialMsgBtn.png'
import ImgClose from '../assets/trialModal/trialMsgCloseBtn.png'
import ImgPhone from '../assets/trialModal/phone.png'
import ImgWecaht from '../assets/trialModal/wechat.png'
// import ImgQQ from '../assets/trialModal/qq.png'
// import ImgQRCode from '../assets/footer.qrcode.png'
import ImgKeFu15 from '../assets/theFooter/kefu15.png'
import ImgKeFu67 from '../assets/theFooter/kefu67.png'
import {
  Popover
} from 'antd'
import css from '../styles/components/TheTrialMsgBtn.module.css'
import dayjs from 'dayjs'

interface IPageProps {}
interface IPageState {
  visible: boolean
}

export default class APP extends React.Component<IPageProps, IPageState> {
  constructor (props: IPageProps) {
    super(props)
    this.state = {
      visible: false
    }
  }

  render () {
    const isWeekend = [0, 6].includes(dayjs().day())
    return <Popover
      placement="topRight"
      title={ null }
      visible={ this.state.visible }
      onVisibleChange={(val) => {
        this.setState({ visible: val })
      }}
      content={<div className={ css.popver_content } style={{ width: 180 }}>
        <div>
          <img src={ ImgPhone }/>
          <span>025-85991069</span>
        </div>
        {/* <div>
          <img src={ ImgQQ }/>
          <a href="https://wpa.qq.com/msgrd?v=3&uin=3290725774&site=qq&menu=yes" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">3290725774</a>
        </div> */}
        <div>
          <img src={ ImgWecaht } style={{ width: 30 }}/>
          <span>好签微信客服</span>
        </div>
        <div style={{ marginBottom: 0 }}>
          <img src={ isWeekend ? ImgKeFu67 : ImgKeFu15 } style={{ width: 76, height: 76, marginLeft: 6 }}/>
        </div>
      </div>}>
      <img
        src={ !this.state.visible ? ImgMsg : ImgClose }
        style={{
          position: 'fixed',
          bottom: 35,
          right: 70,
          width: 70,
          height: 70,
          borderRadius: 35,
          cursor: 'pointer',
          zIndex: 500,
          pointerEvents: 'auto'
        }}/>
    </Popover>
  }
}
